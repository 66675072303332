

















import Vue from 'vue';
import HeroWrapper from '@/components/HeroWrapper.vue'; // @ is an alias to /src
import ActivitiesWrapper from '@/components/ActivitiesWrapper.vue';
import Parallax from '@/components/Parallax.vue';
import WedoWrapper from '@/components/WeDo.vue';
import TeamWrapper from '@/components/TeamWrapper.vue';

export default Vue.extend({
  name: 'Home',
  components: {
    HeroWrapper,
    ActivitiesWrapper,
    Parallax,
    WedoWrapper,
    TeamWrapper,
  },
  methods: {
    goHome() {
      window.scrollTo(0, 0);
    },
  },
});
