



































import Vue from 'vue';

export default Vue.extend({
  name: 'TeamWrapper',
  data() {
    return {

    };
  },

});
