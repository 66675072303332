import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Moralis from 'moralis';
import Home from '../views/Home.vue';
import Fabe from '../views/Fabe.vue';

Vue.use(VueRouter);

async function checkAuthentication() {
  const user:any = Moralis.User.current();
  if (!user) {
    return false;
  }
  const currentUser = user.get('ethAddress');
  const options:any = { chain: 'ropsten', address: currentUser, token_address: '0x70509272387B06132a88dD9c33db6b7c1f56D198' };
  const nfts:any = await Moralis.Web3API.account.getNFTsForContract(options);
  console.log(`Tokens de Fabe del usuario: ${nfts.total}`);
  if (nfts.total > 0) {
    return true;
  }
  return false;
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/fabe',
    name: 'Fabe',
    component: Fabe,
    async beforeEnter(to, from, next) {
      const isFabe = await checkAuthentication();
      if (isFabe) {
        next();
        return;
      }
      console.log('siguiente');
      next('/');
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isFabe = await checkAuthentication();
  if (to.name !== 'Home' && !isFabe) next({ name: 'Home' });
  else next();
});

export default router;
