






















import Vue from 'vue';
import { Network } from 'vis-network/peer';
import { DataSet } from 'vis-data/peer';
import 'vis-network/styles/vis-network.css';

export default Vue.extend({
  name: 'WeDo',
  methods: {
    goHome() {
      window.scrollTo(0, 0);
    },
  },
  data() {
    return {
      nodes: [
        {
          id: 1, label: 'Crypto Infra', shape: 'box', font: '12px arial black', physics: false,
        },
        {
          id: 2, label: 'Use Cases (DeFi, trading, gaming...)', shape: 'box', font: '12px arial black', physics: false,
        },
        {
          id: 3, label: 'Knowledge and Education', shape: 'box', font: '12px arial black', physics: false,
        },
        {
          id: 4, label: 'Fabe Community', shape: 'box', font: '12px arial black', physics: false,
        },
      ],
      edges: [
        {
          id: 1, from: 1, to: 4, font: { size: 10, align: 'middle' }, label: 'Gives assets and ideas', arrows: 'to, from', scaling: { label: true },
        },
        {
          id: 2, from: 1, to: 2, font: { size: 10, align: 'middle' }, label: 'Generates revenues', arrows: 'to, from', scaling: { label: true },
        },
        {
          id: 3, from: 1, to: 3, font: { size: 10, align: 'middle' }, label: 'Trains  builders', arrows: 'to, from', scaling: { label: true },
        },
        {
          id: 4, from: 2, to: 3, font: { size: 10, align: 'middle' }, label: 'Creates knowledge', arrows: 'to, from', scaling: { label: true },
        },
        {
          id: 5, from: 3, to: 4, font: { size: 10, align: 'middle' }, label: 'Attracts members', arrows: 'to, from', scaling: { label: true },
        },
      ],
      bubbles: {},
    };
  },
  mounted() {
    // create an array with nodes
    const nodes = new DataSet(this.nodes);

    // create an array with edges
    const edges = new DataSet(this.edges);

    const data = {
      nodes,
      edges,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const container: any = document.getElementById('bubbles');
    const bubbles = new Network(container, data, { layout: { randomSeed: '0.13217296900635378:1646059974704' }, physics: { enabled: true }, interaction: { zoomView: false, dragView: false } });
    console.log(bubbles.getSeed());
  },
});
