



















































































import Vue from 'vue';
import TypewriterWrapper from './TypewriterWrapper.vue';

export default Vue.extend({
  name: 'ActivitiesWrapper',
  components: {
    TypewriterWrapper,
  },
  data() {
    return {
      activeTab: 0,
      infra: 'Infrastructure',
      invest: 'Crypto-investment',
      build: 'Builders',
    };
  },

});
