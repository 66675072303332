










import Vue from 'vue';
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';

export default Vue.extend({
  name: 'App',
  components: {
    Navbar,
    Footer,
  },
});
