



















import Vue from 'vue';
import Gallery from '@/components/Gallery.vue';

export default Vue.extend({
  components: {
    Gallery,
  },

});
