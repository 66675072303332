







import Vue from 'vue';
import Typewriter from 'typewriter-effect/dist/core';

export default Vue.extend({
  name: 'TypewriterWrapper',
  props: {
    activity: String,
  },
  mounted() {
    const typeSection = this.$refs.typewriter;
    const typewriterInstance = new Typewriter(typeSection, {
      loop: true,
      delay: 75,
    });

    typewriterInstance
      .typeString(`We are <strong>${this.activity}</strong>`)
      .pauseFor(300)
      .deleteChars(27)
      .typeString('<strong>We are crypto</strong>')
      .pauseFor(300)
      .deleteChars(13)
      .typeString('<strong>We are <span style="color: #27ae60;">Fabe</span></strong>')
      .pauseFor(2500)
      .start();
  },
  data() {
    return {
    };
  },

});

