

























































import Moralis from 'moralis';
import Vue from 'vue';

const serverUrl = 'https://rxvkhgntjlro.usemoralis.com:2053/server';
const appId = 'OcJgC8MKAAEtBQI3sa7DqsAsc4yGwFgDN1AVCiNV';
Moralis.start({ serverUrl, appId });

export default Vue.extend({
  components: {
  },
  data() {
    return {
      currentUser: null,
      loggedIn: false,
    };
  },
  async mounted() {
    const user = Moralis.User.current();
    if (user === null) {
      this.loggedIn = false;
      this.currentUser = null;
    } else if (typeof user !== 'undefined') {
      console.log(user.get('ethAddress'));
      this.currentUser = user.get('ethAddress');
      this.loggedIn = true;
      const options:any = { chain: 'ropsten', address: this.currentUser, token_address: '0x70509272387B06132a88dD9c33db6b7c1f56D198' };
      const nfts = await Moralis.Web3API.account.getNFTsForContract(options);
      console.log(nfts);
    }
  },
  methods: {
    async login() {
      console.log('login in');
      let user:any = Moralis.User.current();
      console.log(user);
      if (!user) {
        user = await Moralis.authenticate({ signingMessage: 'Log into Fabe' })
          .then(() => {
            this.loggedIn = true;
            console.log('logged in user:', user);
            console.log(user.get('ethAddress'));
            this.currentUser = user.get('ethAddress');
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (typeof user !== 'undefined') {
        console.log(user.get('ethAddress'));
        alert('Already signed in!');
      } else {
        console.log('Error!');
      }
    },
    async signOut() {
      await Moralis.User.logOut();
      this.loggedIn = false;
      console.log('logged out');
    },
  },

});
