









import Vue from 'vue';
import iFrameResize from 'iframe-resizer/js/iframeResizer';

export default Vue.extend({
  name: 'Gallery',
  directives: {
    resize: {
      bind(el, { value = {} }) {
        el.addEventListener('load', () => iFrameResize(value, el));
      },
    },
  },
});
